<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-card :bordered='false'>
      <span slot='title'>
          限制登录地区
      </span>
      <span slot='extra'>
        <a-button type='primary' @click='handleNew'>添加</a-button>
      </span>
      <div class="tag-list" style="min-height: 300px;">
        <a-tag v-for="(item, index) in regionList" :key="index" closable @close="handleDelete(item)">
          {{item.province}}/{{item.city}}/{{item.district}}
        </a-tag>
      </div>
    </a-card>
    <a-modal title='添加地区' :destroyOnClose='true' :maskClosable='true' :visible='deliver.visible'
             @cancel='()=>this.deliver.visible=false'
             ok-text='保存' cancel-text='取消' @ok='handleDeliver' :confirmLoading='deliver.loading'>
      <a-form-model ref='form' :model='deliver.form' :rules='deliver.rules' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
        <a-row type="flex" justify="space-between" align="middle" v-for="(item, index) in deliver.form.regionList" :key="index">
          <a-col :span="14">
            <a-form-model-item style="margin-bottom: 0" label='地区' prop='region'>
              <a-cascader
                :field-names="{ label: 'name', value: 'adcode', children: 'districts' }"
                :options="region"
                :show-search="{ filter }"
                :allowClear="false"
                placeholder="请选择"
                @change="onChange(index, arguments[1])"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <img @click="addRegion(index)" style="width: 30px; height: 30px;margin-right: 20px" :src="addImg" >
            <img @click="deleteRegion(index)" v-if="index != 0" style="width: 30px; height: 30px" :src="deleteImg" >
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { queryLimitRegion, deleteLimitRegion, putLimitRegion } from '@/api/limit'
import region from '@/config/region'

export default {
  name: 'LimitPositionList',
  components: {
    STable, Ellipsis
  },
  data() {
    return {
      addImg: require('@/assets/add.png'),
      deleteImg: require('@/assets/delete.png'),
      region,
      regionList: [],
      deliver: { 
        visible: false, 
        loading: false,
        form: {},
        rules: {} 
      }, 
    }
  },
  created() {
    this.handleInitial();
  },
  methods: {
    handleInitial() {
      queryLimitRegion().then(datum => {
        this.regionList = datum
        this.$forceUpdate()
      })
    },
    filter(inputValue, path) {
      return path.some(option => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },
    onChange(index, value) {
      this.deliver.form.regionList[index] = {
        province: value[0].name,
        city: value[1].name,
        district: value[2].name,
      }
    },
    addRegion(index){
      this.deliver.form.regionList.splice(index+1,0,{          
        province: "",
        city: "",
        district: "",
      });
    },
    deleteRegion(index){
      this.deliver.form.regionList.splice(index,1);
    },
    handleNew() {
      this.deliver.visible = true;
      this.deliver.form = {
        regionList: [{          
          province: "",
          city: "",
          district: "",
        }]
      };
    },
    handleDeliver() {
      const requestList = [];
      this.deliver.form.regionList.forEach(item => {     
        if (item.province && item.city && item.district) {     
          requestList.push(putLimitRegion(item))
        }
      });
      if (requestList.length == 0) {
        this.$message.warning('至少选择一个地区');
        return;
      }
      this.deliver.loading = true;
      Promise.all(requestList).then(result => {
        this.$message.success('操作成功');
        this.handleInitial();
        this.deliver.visible = false;
        this.deliver.loading = false;
      }).catch(() => {
        this.deliver.loading = false;
      })
    },
    handleDelete(record) {
      deleteLimitRegion({ id: record.id }).then(result => {
        this.$message.success('操作成功');
        this.handleInitial();
      })
    }
  }
}
</script>
