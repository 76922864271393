import request from '@/utils/request'

export function queryLimitRegion() {
  return request({
    url: '/login/black/list/list',
    method: 'get'
  })
}

export function deleteLimitRegion(parameter) {
  return request({
    url: '/login/black/list/delete/' + parameter.id,
    method: 'DELETE'
  })
}

export function putLimitRegion(parameter) {
  return request({
    url: '/login/black/list',
    method: 'post',
    data: parameter
  })
}